import { useRouter } from 'next/router';
import { getHome, getRecentArticles } from '../lib/storyblok/graphql';
import SliceContent from '../components/SliceContent';
import Layout from "../components/Layout";

export default function Home({articles, document}) {
    const router = useRouter();
    const currentRoute = router.pathname;
    const { 
      seo_title,
      seo_image, 
      seo_description, 
      seo_type, 
      seo_canonical,
      seo_audio,
      seo_video
    } = document;

    function addAWebsiteJsonLd() {
      return {
        __html: `{
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "Richard Bilton",
            "url": "https://www.richardbilton.com"
          }`,
      };
    }
    

    return (
      <Layout
          currentRoute={currentRoute}
          title={seo_title}
          image={seo_image}
          description={seo_description}
          type={seo_type}
          canonical={seo_canonical}
          audio={seo_audio}
          video={seo_video}
          schema={addAWebsiteJsonLd()}
        >
        <SliceContent content={document.body} articles={articles} />
      </Layout>
    )
}

export async function getStaticProps() {
    const recentArticles = (await getRecentArticles()) || []
    const document = await getHome(); 


    return {
      props: {
        articles: recentArticles,
        document: document
      }, // will be passed to the page component as props
    }
  }