import dynamic from 'next/dynamic';
import React from 'react';

const RichTextBlock = dynamic(() =>
  import('./index').then(mod => mod.RichTextBlock)
)
const RichText = dynamic(() =>
  import('./index').then(mod => mod.RichText)
)
const LatestPosts = dynamic(() =>
  import('./index').then(mod => mod.LatestPosts)
)
const BlogSection = dynamic(() =>
  import('./index').then(mod => mod.BlogSection)
)
const Hero = dynamic(() =>
  import('./index').then(mod => mod.Hero)
)

const SliceContent = ({ content, page, articles }) => (
    content.map((block, index) => {
        switch (block.component) {
            case "hero":
                return <Hero key={`section-${index}`} data={block} />;
            case "recentposts":
                return <LatestPosts key={`section-${index}`} data={block} articles={articles} />;
            case "blogPlaceHolder": 
                return (
                    <div key={`section-${index}`} className="max-w-7xl mx-auto px-8 mb-16">
                        <BlogSection articles={articles}/>
                    </div>
                );
            case "richtext":
                return (
                    <RichTextBlock key={`section-${index}`}>
                        <RichText content={block.richtext} />
                    </RichTextBlock>
                );
           
        } 
    })
);
export default SliceContent; 